import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  useMediaQuery,
  Typography,
  GridList,
  GridListTile,
  IconButton,
} from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import { Image } from 'components/atoms';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';

const Bold = ({ children }) => <b>{children}</b>;
const Italic = ({ children }) => <i>{children}</i>;
const Underline = ({ children }) => <u>{children}</u>;
const Text = ({ children }) => (
  <Typography component="p" variant="h6" color="textPrimary">
    {children}
  </Typography>
);

const options = {
  renderText: text =>
    text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    [MARKS.ITALIC]: text => <Italic>{text}</Italic>,
    [MARKS.UNDERLINE]: text => <Underline>{text}</Underline>,
  },
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children) => (
      <Typography
        component="p"
        variant="h3"
        color="textPrimary"
        align="left"
        gutterBottom
      >
        <b>{children}</b>
      </Typography>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <Typography
        component="p"
        variant="h3"
        color="textPrimary"
        align="left"
        gutterBottom
      >
        <b>{children}</b>
      </Typography>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <Typography
        component="p"
        variant="h3"
        color="textPrimary"
        align="left"
        gutterBottom
      >
        <b>{children}</b>
      </Typography>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <Typography
        component="p"
        variant="h4"
        color="textPrimary"
        align="left"
        gutterBottom
      >
        <b>{children}</b>
      </Typography>
    ),
    [BLOCKS.HEADING_5]: (node, children) => (
      <Typography
        component="p"
        variant="h5"
        color="textPrimary"
        align="left"
        gutterBottom
      >
        <b>{children}</b>
      </Typography>
    ),
    [BLOCKS.HEADING_6]: (node, children) => (
      <Typography
        component="p"
        variant="h5"
        color="textPrimary"
        align="left"
        gutterBottom
      >
        <b>{children}</b>
      </Typography>
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [BLOCKS.EMBEDDED_ASSET]: node => {
      return (
        <>
          <Image src={node.data.target.fixed.src} />
        </>
      );
    },
  },
};

const useStyles = makeStyles(theme => ({
  section: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(4),
    },
  },
  image: {
    objectFit: 'cover',
    borderRadius: theme.spacing(1),
  },
  socialIcon: {
    borderRadius: 0,
    marginRight: theme.spacing(2),
    color: theme.palette.text.primary,
    background: theme.palette.alternate.main,
    '&:last-child': {
      marginRight: 0,
    },
  },
}));

const Content = ({
  data,
  className,
  ...rest
}: ViewComponentProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      <div className={classes.section}>{renderRichText(data, options)}</div>
      <div>
        <IconButton className={classes.socialIcon}>
          <FacebookIcon />
        </IconButton>
        <IconButton className={classes.socialIcon}>
          <InstagramIcon />
        </IconButton>
        <IconButton className={classes.socialIcon}>
          <TwitterIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default Content;
