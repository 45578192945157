import React from 'react';
import BlogArticle from 'views/BlogArticle';
import Main from 'layouts/Main';
import WithLayout from 'WithLayout';
import { graphql } from 'gatsby';

const BlogArticlePage = ({ data }): JSX.Element => {
	return (
		<WithLayout
			component={BlogArticle}
			layout={Main}
			data={data.contentfulBlogPost}
		/>
	);
};

export default BlogArticlePage;

export const query = graphql`
	query blogPost($slug: String!) {
		contentfulBlogPost(slug: { eq: $slug }, datePosted: { ne: null }) {
			blogContent {
				raw
			}
			datePosted
			title
			id
			slug
			subtitle
			tags
			imageCover {
				id
				fluid {
					src
					srcSet
				}
			}
			postsYouMightLike {
				title
				subtitle
				tags
				slug
				imageCover {
					fluid {
						src
						srcSet
					}
				}
				datePosted
				author {
					authorName
					authorImage {
						fluid {
							src
							srcSet
						}
					}
				}
			}
			author {
				authorName
				authorImage {
					fluid {
						src
						srcSet
					}
				}
			}
		}
	}
`;
