import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Section, SectionAlternate } from 'components/organisms';
import { Content, FooterNewsletter, Hero, SimilarStories } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
  },
  sidebarNewsletter: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  footerNewsletterSection: {
    background: theme.palette.primary.dark,
  },
}));

const BlogArticle = ({ data }): JSX.Element => {
  const classes = useStyles();

  const author = {
    photo: {
      src: data.author.authorImage.fluid.src,
      srcSet: data.author.authorImage.fluid.srcSet,
    },
    name: data.author.authorName,
    date: data.datePosted,
  };

  const cover = {
    src: data.imageCover.fluid.src,
    srcSet: data.imageCover.fluid.srcSet,
  };

  const similarStories = data.postsYouMightLike.map(data => {
    return {
      cover: {
        src: data.imageCover.fluid.src,
        srcSet: data.imageCover.fluid.srcSet,
      },
      title: data.title,
      subtitle: data.subtitle,
      author: {
        photo: {
          src: data.author.authorImage.fluid.src,
          srcSet: data.author.authorImage.fluid.srcSet,
        },
        name: data.author.authorName,
      },
      date: data.datePosted,
      tags: data.tags ? data.tags : [],
      slug: data.slug,
    };
  });

  return (
    <div className={classes.root}>
      <Hero
        cover={cover}
        title={data.title}
        subtitle={data.subtitle}
        author={author}
      />
      <Section>
        <Grid container spacing={4}>
          <Grid item xs={12} md={12}>
            <Content data={data.blogContent} />
          </Grid>
        </Grid>
      </Section>
      <SectionAlternate>
        <SimilarStories data={similarStories} />
      </SectionAlternate>
    </div>
  );
};

export default BlogArticle;
